import * as React from "react"

const Logo = (props) => (
  <svg
    viewBox="0 0 375 167"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M268.743 135.236c-27.923 0-50.559-23.116-50.559-51.631s22.636-51.631 50.559-51.631 50.559 23.116 50.559 51.631-22.636 51.631-50.559 51.631m0-131.982c-43.455 0-78.683 35.974-78.683 80.351s35.228 80.351 78.683 80.351c43.456 0 78.683-35.974 78.683-80.351S312.199 3.254 268.743 3.254M160.643 62.769l-97.5-56.292C51.651-.158 36.956 3.78 30.322 15.271c-6.635 11.492-2.697 26.186 8.794 32.821l62.158 35.887V73.744L43.019 40.11c-7.252-4.186-9.737-13.46-5.55-20.712 4.187-7.253 13.461-9.738 20.713-5.551l97.782 56.455c7.252 4.187 9.737 13.46 5.55 20.713-2.042 3.537-4.899 6.622-8.535 7.67l-12.79 7.385c10.809 4.07 23.284-.15 29.249-10.48 6.634-11.492 2.697-26.187-8.795-32.821"
      style={{
        fill: "#f2f2f2",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M169.438 71.62c-5.975-10.348-18.484-14.566-29.306-10.459l13.157 7.596c3.572 1.072 6.601 3.729 8.613 7.214 4.187 7.252 1.702 16.526-5.55 20.713L58.57 153.138c-7.252 4.187-16.525 1.703-20.713-5.55-4.187-7.252-1.702-16.526 5.55-20.713l57.638-33.277V83.363l-61.929 35.755c-11.492 6.635-15.429 21.329-8.794 32.821 6.635 11.491 21.329 15.429 32.82 8.794l97.501-56.292c11.492-6.634 15.429-21.329 8.795-32.821"
      style={{
        fill: "#f2f2f2",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M97.934 72.943v4.295h2.64v10.963h-2.64v6.084c5.528-.424 9.885-5.034 9.885-10.671s-4.357-10.247-9.885-10.671"
      style={{
        fill: "#f2f2f2",
        fillRule: "nonzero",
      }}
    />
  </svg>
)

export default Logo
