import { Button, Col, Row } from 'antd'
import React from 'react'
import "./style.css"

function Contact() {
    const contactContent = [{
            link: "mailto:info@twentyfoxtech.com",
            title: "Email"
        },
        // {
        //     link: "",
        //     title: "Phone"
        // },
        {
            link: "https://www.linkedin.com/twentyfoxtech/",
            title: "LinkedIn"
        },
        {
            link: "https://www.instagram.com/twentyfoxtech/",
            title: "Instagram"
        },
        {
            link: "https://www.twitter.com/twentyfoxtech/",
            title: "Twitter"
        },
        // {
        //     link: "wa.me/+917200432523",
        //     title: "Whatsapp"
        // }
    ]
    return (
        <div className='contact--content'>
            <div className='contact--text'>
                <p className='contact-title font-size__300'>contact us</p>
                {contactContent.map((item, index) => {
                    return (
                        <Button className='contact-button' shape='round' onClick={()=>window.open(item.link,'_blank').focus()}>
                            <a>{item.title}</a>
                        </Button>
                    )
                })}
            </div>

        </div>)
}

export default Contact