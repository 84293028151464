import { Button, Col, Row } from 'antd'
import React from 'react'
import "./style.css"
import Tgda from "./tgda"
import mocxam from "../../Assets/images/Transparent.png"

function Projects() {

    const projectDatas = [{
            id: 1,
            bgColor: "background--orange",
            type: "Website",
            title1: "tardigrada",
            title2: "design studio",
            description: "Strategic partners specializing in branding and visual experiences. Branding, UX/UI, Environmental graphics, Motion graphics, Illustration, Packaging & Editorial.",
            button: "View tardigrada design studio",
            link:"https://tardigradastudio.com/",
            component: <Tgda />
        },
        {
            id: 2,
            bgColor: "background--purple",
            type: "mobile app",
            title2: "mocxam",
            description: "A Competitive exam preparation app that empowers student to make an Impact. Mocxam provides subject wise, short and full length exams, which in end will provide you a detailed analysis of your preparation with instant all India rank.",
            button: "View on play store",
            link:"https://play.google.com/store/apps/details?id=com.twentyfox.mocxam",
            image: mocxam
        }
    ]

    return (
        <div className="project--section" style={{paddingBottom:"10%"}}>
            {
                projectDatas.map((item, index) => {
                    return (
                        <div className='project--content'>
                            <div className={`${item.bgColor} project`}>
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={12} xl={12}>
                                        <div className='project--left-portion'>
                                            <p className='project--type font-size__125'>{item.type}</p>
                                            <p className='project--title font-size__300'>{item && item.title1 && <span>{item.title1}<br /></span>}{item.title2}</p>
                                            <p className='project--description font-size__125'>{item.description}</p>
                                            <Button className='project--button' size="large" onClick={()=>window.open(item.link, '_blank').focus()}>{item.button}</Button>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} xl={12}>
                                        <div className='project--right-portion'>
                                            <div className='project--image'>
                                                {item && item.component && <Tgda width="350px" height="350px" />}
                                                {item && item.image && <img src={item.image} className="project--image-mocxam" alt="project--img" />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        

    )
}

export default Projects