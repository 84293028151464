import React, { useEffect, useState } from 'react';
import "./style.css"
import Abstract from "../../Assets/images/banner.png"

function Home() {

    const [visibleText, setVisibleText] = useState(1)
    const [visibleHomePage, setVisibleHomePage] = useState(true)

    useEffect(() => {
        let abstract = document.getElementById("home--abstract");

        window.addEventListener("scroll", function () {
            abstract.style.transform = "rotate(-" + window.scrollY / 12 + "deg)";
            const winScroll = document.documentElement.scrollTop;
            if (winScroll <= 600) {
                setVisibleText(1)
            } else if (winScroll <= 1200) {
                setVisibleText(2)
            } else if (winScroll > 1200) {
                setVisibleText(3)
            }  if (winScroll > 2800) {
                setVisibleText(4)
            }
            if (winScroll > 4055) {
                setVisibleHomePage(false)
            }
            if (winScroll < 4055) {
                setVisibleHomePage(true)
            }
        });

    }, [])

    return (
        <div className={`${visibleHomePage ? "show--page" : "hide--page"} home--content`}>
            <div className='home--image'>
                <img src={Abstract} id='home--abstract' className='home--abstract' alt='abstract' />
            </div>

            <div className='home--text' >
                <p className='home--title font-size__300'> TwentyFox</p>
                <p className={`${visibleText === 1 ? "show--description" : "hide--description"} home--description font-size__125`}>Website Design and Development</p>
                <p className={`${visibleText === 2 ? "show--description" : "hide--description"} home--description font-size__125`}>Mobile App Design and Development</p>
                <p className={`${visibleText === 3 ? "show--description" : "hide--description"} home--description font-size__125`}>UI/UX and  Graphics Design</p>
                <p className={`${visibleText === 4 ? "show--description" : "hide--description"} home--description font-size__125`} style={{display:"none"}}>-</p>
            </div>
        </ div>
    )
}

export default Home;
