import { useEffect, useState } from 'react';
import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Home from './Pages/Home';
import MobileAppServices from './Pages/Mobileapp services';
import ProjectTitle from './Pages/Project';
import Projects from './Pages/Projects';
import Services from './Pages/Services';
import Team from './Pages/Team';
import WebAppServices from './Pages/Webapp services';

function App() {
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    const winScroll = document.documentElement.scrollTop;
    if (winScroll > 2855) {
      setDisplay(false)
    }
    if (winScroll < 2855) {
      setDisplay(true)
    }
  })

  return (
    <div className="App">
      <Header />
      <Home/>
      <About />
      <Services />
      <WebAppServices />
      <MobileAppServices/>
      <ProjectTitle/>
      <Projects/>
      {/* <Team/> */}
      <Contact/>
      <Footer />
    </div>
  );
}

export default App;
