import React from 'react';
import "./style.css"

function Services() {
    return (
        <div className='services--content'>
            <p className='services--title font-size__900'>our services</p>
            <div className='services--abstract'></div>
        </div>)
}

export default Services;
