import React, { useEffect, useState } from 'react'
import "./style.css"
import { Tween } from 'react-gsap';


function MobileAppServices() {
    const [leftPortion, setLeftPortion] = useState(false)
    const [from_, setFrom_] = useState("0px")
    const [region, setRegion] = useState("top")
    const [isPortrait, setIsPortrait] = useState(false)
    const [isLandscape, setIsLandscape] = useState(false)





    useEffect(() => {
        window.addEventListener("scroll", () => {
            const winScroll = document.documentElement.scrollTop;
            const divScroll = document.getElementById("mobileapp").getBoundingClientRect();
            // console.log(divScroll.bottom, window.innerHeight , winScroll );
            // console.log(winScroll);
            // console.log(descriptionScroll.top,descriptionScroll.bottom);

            if (window.matchMedia("(orientation:landscape)").matches && !window.matchMedia("(min-width: 1200px)").matches) {
                let isTab_ = window.matchMedia("(min-height:768px").matches
                let isTabPro_ = window.matchMedia("(min-height:820px").matches
                if (isTabPro_) {
                    // tab pro landscape 
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.33780487804878)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 9098 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                } else if (isTab_) {
                    // tab landscape
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.345052083333333)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 8768 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                } else {
                    // mobile device landscape
                    if (divScroll.top < 0) {
                        setIsLandscape(true)

                    }
                }
            }
            else if (window.matchMedia("(min-width: 1600px)").matches) {
                // laptop 
                if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.554621848739496)) < 0) {
                    setLeftPortion(false)
                    setRegion(winScroll < 8730 ? "top" : "bottom")
                } else if (divScroll.top < 0) {
                    setLeftPortion(true)
                }
            }
            else if (window.matchMedia("(min-width: 1400px)").matches) {
                // laptop 
                if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.246498599439776)) < 0) {
                    setLeftPortion(false)
                    setRegion(winScroll < 7828 ? "top" : "bottom")
                } else if (divScroll.top < 0) {
                    setLeftPortion(true)
                }
            }
            else if (window.matchMedia("(min-width: 1200px)").matches) {
                // laptop 
                if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.246498599439776)) < 0) {
                    setLeftPortion(false)
                    setRegion(winScroll < 7373 ? "top" : "bottom")
                } else if (divScroll.top < 0) {
                    setLeftPortion(true)
                }
            }
            else if (window.matchMedia("(orientation:portrait)").matches) {
                if (window.matchMedia("(min-width: 820px)").matches) {
                    // tab pro portrait 
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.153389830508475)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 10587 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                }
                else if (window.matchMedia("(min-width:768px)").matches) {
                    // tab portrait 
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.5419921875)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 10232 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                } else if (window.matchMedia("(max-width:600px)").matches) {
                    // mobile 
                    if (divScroll.top < 0) {
                        setIsPortrait(true)
                    }
                }
            }

        })
    }, [])
    return (
        <div className='mobileappservices--content' id='mobileapp'>
            <div className='flex__spaceBetween width__100'>
                <Tween to={{ y: from_ }} duration={1} ease="back.out(0)">
                    <div className={`${leftPortion ? "leftPortion--fixed" : region == "top" ? "mobileapp--top" : "mobileapp--bottom"} ${isLandscape && "leftPortion--absolute"} ${isPortrait && "leftPortion--absolute"} webappservices--left width__53 flex__spaceBetween`}>
                        <div className='mobileappservices--title--content width__50 flex__spaceEvenly'>
                            <p className='mobileappservices--title font-size__300'>Mobile App Design <br/> and <br/>Development</p>
                        </div>
                        <div className='mobileappservices--image width__50'>
                            <div className='mobileappservices--abstract'></div>
                        </div>
                    </div>
                </Tween>

                <div className='mobileappservices--right width__47 fixed'>
                    <p className="mobileappservices--description font-size__181">React Native is the technology to highlight
                     in the development process that we follow, It supports building the apps in native language
                      thus providing us a solution to code once and develop both iOS and android apps will all 
                      Native capabilities of the operating system. 
                    </p>
                    <p className="mobileappservices--description font-size__181">We have developed more than 7 apps using react 
                    native. Gaming, Marketing, Education, Entertainment, personalisation are the domains that we 
                    have worked on so far in mobile app development. We handle end to end development activities 
                    of mobile app development from the process of planning the application architecture, design, 
                    development to deployment in stores. 
                    </p>
                    <p className="mobileappservices--description font-size__181">Technologies: React Native, Android, iOS, Native Web,
                     Node JS, Python, UI JS Frameworks. AWS Services: Lambda, Dynamo DB, API Gateway, EC2, CloudWatch, S3.
                      Firebase Services: Analytics, Authentication, Push Notification. Payment gateways: Razorpay, PayU, Paytm.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default MobileAppServices