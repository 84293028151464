import { Button } from 'antd';
import React from 'react';
import Logo from '../../Assets/logo/logo';
import "./style.css"

function Header() {

    const toggleContact = () => {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }

    const scrollUp = () => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }

    const goToStore =()=>{
        window.open("https://play.google.com/store/apps/developer?id=TwentyFox", '_blank').focus();
    }

    return (
        <div className='header--content'>
            <div className='fixed logo--content'>
                <Logo onClick={scrollUp} className='logo' />
            </div>
            <div className='header--button fixed'>
                <Button type="link" className='header--btn btn-1' onClick={goToStore}>Store</Button>
                <Button type="link" className='header--btn btn-2' onClick={toggleContact}>Contact</Button>
            </div>
        </div>)
}

export default Header;
