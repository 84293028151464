import * as React from "react"

const Tgda = (props) => (
  <svg viewBox="0 0 781 773" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <clipPath id="a">
        <path d="M0 0h781v773H0z" />
      </clipPath>
    </defs>
    <g
      data-name="Logo symbol"
      clipPath="url(#a)"
      transform="matrix(1.01016 0 0 1 -3.896 0)"
    >
      <path
        data-name="Path 250"
        d="M390.5.109C177.412.109 4.054 173.467 4.054 386.555S177.412 772.999 390.5 772.999s386.444-173.357 386.444-386.444S603.583.109 390.5.109Zm220.938 445.646-59.2 102.536-102.538 59.2H331.3l-102.538-59.2-59.2-102.536v-118.4l59.2-102.536 102.538-59.2h118.4l102.538 59.2 59.2 102.536Z"
        fill="#121212"
      />
    </g>
  </svg>
)

export default Tgda;