import React, { useEffect, useState } from 'react';
import "./style.css"
import { Popover, Button, Progress } from 'antd';
import { InstagramOutlined } from '@ant-design/icons';

const content = (
  <div className='socialMediaIcons'>
    <a onClick={() => window.open("https://www.youtube.com/channel/UCpakMQbCQ0Y3Oaof14BJVdA", '_blank').focus()}><i class="fab fa-youtube"></i></a>
    <a onClick={() => window.open("https://www.twitter.com/twentyfoxtech/", '_blank').focus()}><i class="fab fa-twitter"></i></a>
    <a onClick={() => window.open("https://www.instagram.com/twentyfoxtech/", '_blank').focus()}><InstagramOutlined /></a>
  </div>
)

function Footer() {

  const [rotate, setRotate] = useState("0deg");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const winScroll = document.documentElement.scrollTop;
      let progress_ = Math.round((winScroll / (document.body.scrollHeight - window.innerHeight)) * 100)
      setProgress(progress_)
    })
    window.addEventListener("mousemove", () => {
      setTimeout(() => {
        toogle()

      }, 350);
    })
  }, [])

  const toogleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }


  const toogle = () => {
    let antButton = document.querySelector(".ant-popover-placement-top")
    let antButtonResult;
    if (antButton) {
      antButtonResult = antButton.classList.contains('ant-popover-hidden');
    }
    if (antButtonResult === false) {
      setRotate("135deg")
    } else {
      setRotate("0deg")
    }
  }

  return (
    <div id="footer" className='footer--content'>
      <div className='fixed socialMedia--content'>
        <Popover content={content} >
          <Button shape="circle" size="medium" className='footer--button'>
            <i style={{ fontSize: "21px", marginTop: "-0.5px", transform: `rotate(${rotate})` }} class="material-icons" >add</i>
          </Button>
        </Popover>
      </div>
      <div className='progress--button fixed' onClick={toogleClick}>
        {progress > 30 && <i class="fas fa-chevron-up" style={{ marginRight: '-23px' }}></i>}
        <Progress type="circle" strokeColor="white" width={33} percent={progress} />
      </div>
    </div>)
}

export default Footer;
