import React, { useEffect, useState } from 'react';
import "./style.css"
import { Tween } from 'react-gsap';

function WebAppServices() {

    const [leftPortion, setLeftPortion] = useState(false)
    const [from_, setFrom_] = useState("0px")
    const [region, setRegion] = useState("top")
    const [isPortrait, setIsPortrait] = useState(false)
    const [isLandscape, setIsLandscape] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const winScroll = document.documentElement.scrollTop;
            const divScroll = document.getElementById("webapp").getBoundingClientRect();
            // console.log(divScroll.bottom, window.innerHeight , winScroll );
            // console.log(winScroll);
            // console.log(descriptionScroll.top,descriptionScroll.bottom);

            if (window.matchMedia("(orientation:landscape)").matches && !window.matchMedia("(min-width: 1200px)").matches) {
                let isTab_ = window.matchMedia("(min-height:768px").matches
                let isTabPro_ = window.matchMedia("(min-height:820px").matches
                if (isTabPro_) {
                    // tab pro landscape 
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.513414634146341)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 6068 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                } else if (isTab_) {
                    // tab landscape
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.256510416666667)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 5917 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                } else {
                    // mobile device landscape
                    if (divScroll.top < 0) {
                        setIsLandscape(true)

                    }
                }
            }
            else if (window.matchMedia("(min-width: 1600px)").matches) {
                // laptop 
                if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.554621848739496)) < 0) {
                    setLeftPortion(false)
                    setRegion(winScroll < 5870 ? "top" : "bottom")
                } else if (divScroll.top < 0) {
                    setLeftPortion(true)
                }
            }
            else if (window.matchMedia("(min-width: 1400px)").matches) {
                // laptop 
                if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.554621848739496)) < 0) {
                    setLeftPortion(false)
                    setRegion(winScroll < 5461 ? "top" : "bottom")
                } else if (divScroll.top < 0) {
                    setLeftPortion(true)
                }
            }
            else if (window.matchMedia("(min-width: 1200px)").matches) {
                // laptop 
                if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.554621848739496)) < 0) {
                    setLeftPortion(false)
                    setRegion(winScroll < 5070 ? "top" : "bottom")
                } else if (divScroll.top < 0) {
                    setLeftPortion(true)
                }
            }
            else if (window.matchMedia("(orientation:portrait)").matches) {
                if (window.matchMedia("(min-width: 820px)").matches) {
                    // tab pro portrait 
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.283050847457627)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 7098 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                }
                else if (window.matchMedia("(min-width:768px)").matches) {
                    // tab portrait 
                    if (divScroll.top > 0 || (divScroll.bottom - (window.innerHeight * 1.7568359375)) < 0) {
                        setLeftPortion(false)
                        setRegion(winScroll < 6528 ? "top" : "bottom")
                    } else if (divScroll.top < 0) {
                        setLeftPortion(true)
                    }
                } else if (window.matchMedia("(max-width:600px)").matches) {
                    // mobile 
                    if (divScroll.top < 0) {
                        setIsPortrait(true)
                    }
                }
            }

        })
    }, [])

    return (
        <div className='webappservices--content' id="webapp">
            <div className='flex__spaceBetween width__100'>
                <Tween to={{ y: from_ }} duration={1} ease="back.out(0)">
                    <div className={`${leftPortion ? "leftPortion--fixed" : region == "top" ? "webapp--top": "webapp--bottom"} ${isLandscape && "leftPortion--absolute"} ${isPortrait && "leftPortion--absolute"} webappservices--left width__53 flex__spaceBetween`}>
                        <div className='webappservices--title--content  width__50 flex__spaceEvenly'>
                            <p className='webappservices--title font-size__300'>Website Design and Development</p>
                        </div>
                        <div className='webappservices--image'>
                            <div className='webappservices--abstract'></div>
                        </div>
                    </div>
                </Tween>

                <div className='webappservices--right width__47 '>
                    <p className="webappservices--description font-size__181">With 3 years of extensive experience in web development we have delivered more than
                        10 websites as of now. We mostly suggest AWS services for our clients to minimise
                        the maintenance cost and reliability of the services.
                    </p>
                    <p className="webappservices--description font-size__181">Mostly we will go for servers only when explicitly needed or else we suggest building
                        a server less architecture for the betterment of maintenance and cost. We handle end
                        to end web development starting from architecture planning, design, development to
                        deployment including payment gateway integration.
                    </p>
                    <p id="desc" className="webappservices--description font-size__181">Technologies: React JS, Node JS, Python and UI JS Frameworks. AWS Services: Lambda, ECR, Dynamo DB, API Gateway, EC2, SageMaker, CloudWatch, S3, Route 53. Firebase Services: Analytics, Authentication, Hosting and Storage.
                        Payment gateways: Razorpay, PayU, Paytm.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WebAppServices