import React from 'react';
import "./style.css"

function About() {
    return (
        <div className='about--content'>
            <p className='about--title font-size__900'>about us</p>
            <p className='about--description font-size__181'>we are a team of young and energetic developers, involved in delivering the
                requirements of various clients in various technologies. We develop end to
                end web and mobile applications. We have a great team of designers, developers
                and QA with a very strong technical knowledge.
            </p>
            <p className='about--description font-size__181'> We have worked on various domains
                like gaming, insurance, marketing and e-commerce sites and mobile apps. We do have
                a product Mocxam, which helps students to prepare for their exams, get to know
                their progress in preparation, analytics and statistics of the marks they have scored.
            </p>
        </div>)
}

export default About;
